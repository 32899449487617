<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getListStatementSailorPassport"
)
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'StatementSailorPasspport',
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      headers: [
        { value: 'date_create',
          text: this.$t('createDate')
        },
        { value: 'number',
          text: this.$t('number')
        },
        { value: 'sailorFullName',
          text: this.$t('fullName'),
          sortable: false
        },
        { value: 'port',
          text: this.$t('port')
        },
        { value: 'type_receipt',
          text: this.$t('receiveDoc')
        },
        { value: 'is_payed',
          text: this.$t('payment')
        },
        { value: 'status_document',
          text: this.$t('status')
        },
        { value: 'event',
          text: this.$t('actions'),
          class: 'mw-0',
          sortable: false
        }
      ],
      actions: [
        {
          id: 1,
          to: ({ sailor, id }) => ({ name: 'passports-statements-info', params: { id: sailor.id, documentID: id }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.statement.statementSailorPassport,
      isLoading: state => state.statement.isLoading
    })
  },
  methods: {
    ...mapActions(['getListStatementSailorPassport'])
  }
}
</script>
